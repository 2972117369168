import React from 'react';
import './styles.partd.css';

import ESC_model from '../../../assets/img/produc-3.png'
import { Link } from 'react-router-dom';

function Partd() {
    return (
        <div className="intro">
            <div className='intro-img' data-aos='zoom-in'>
                <img src={ESC_model} alt='intro-esc' width={300} className='intro-image' />
            </div>
            <div className='intro-content' data-aos='fade-down'>
                <div className='intro-sup'>
                    ESC Graphene Based Solid State Batteries
                </div>
                <div className='intro-description'>Our standard energy storage modules feature a voltage range of 3VDC to 72VDC (or custom) and a capacity of up to 15,750 watt hours per unit. They support 50A - 200A charge/discharge currents (or custom), with a maximum pulse discharge up to 250A (or custom), depending on your needs. Operating from -20°C to 60°C, our batteries boast a lifespan of over 20 years and over 30,000 cycles. Compatible with all inverters such as Victron and SMA, they are engineered for long-lasting performance in parallel or series systems.  Custom designed units with any voltage, amperage and KwH requirements are our specialty, as we customize our batteries for every application and customer. Just tell us your needs and we will build and install the battery or battery system that you need.</div>
                <div className='intro-sub'>
                    <div className='intro-button'>
                        <Link to='/products'>Show Products</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Partd;
