// src/pages/calculator/index.js
import './styles.calculator.css';
import React, { useState, useEffect } from 'react';
import RouterBar from '../../components/routerBar';

const routerData = [
  { router: "ROI Calculator", link: "/roi-calculator" },
];

function Calculator() {
  // State variables for user inputs
  const [offPeakPrice, setOffPeakPrice] = useState('0.05');
  const [onPeakPrice, setOnPeakPrice] = useState('0.30');  
  const [batteryInvestment, setBatteryInvestment] = useState(20000);
  const [totalDailyEnergy, setTotalDailyEnergy] = useState(25);
  const [peakUsagePercent, setPeakUsagePercent] = useState(90);

  // Fixed parameters
  const batteryCostPerKWh = 950; 
  const batteryEfficiency = 0.95; // 95%

  // State variable for battery coverage percentage
  const [batteryCoveragePercentage, setBatteryCoveragePercentage] = useState(0);

  let offPeakPriceNum = parseFloat(offPeakPrice);
  if (isNaN(offPeakPriceNum)) offPeakPriceNum = 0;

  let onPeakPriceNum = parseFloat(onPeakPrice);
  if (isNaN(onPeakPriceNum)) onPeakPriceNum = 0;

  // Calculations
  const peakEnergy = (totalDailyEnergy * peakUsagePercent) / 100;
  const offPeakEnergy = totalDailyEnergy - peakEnergy;
  const safePeakEnergy = peakEnergy > 0 ? peakEnergy : 1;

  // Function to update batteryCoveragePercentage when batteryInvestment changes
  const updateCoverageFromInvestment = (investment) => {
    const batteryCapacity = investment / batteryCostPerKWh;
    const effectiveBatteryCapacity = batteryCapacity * batteryEfficiency;
    const coverage = (effectiveBatteryCapacity / safePeakEnergy) * 100;
    setBatteryCoveragePercentage(Math.min(coverage, 100));
  };

  // Function to update batteryInvestment when batteryCoveragePercentage changes
  const updateInvestmentFromCoverage = (coverage) => {
    const requiredEffectiveCapacity = (coverage / 100) * safePeakEnergy;
    const requiredBatteryCapacity = requiredEffectiveCapacity / batteryEfficiency;
    const investment = requiredBatteryCapacity * batteryCostPerKWh;
    setBatteryInvestment(Math.min(investment, 75000));
  };

  // Initialize batteryCoveragePercentage based on initial batteryInvestment
  useEffect(() => {
    updateCoverageFromInvestment(batteryInvestment);
  }, [totalDailyEnergy, peakUsagePercent, batteryInvestment]);

  // Remaining calculations
  const batteryCapacity = batteryInvestment / batteryCostPerKWh;
  const effectiveBatteryCapacity = batteryCapacity * batteryEfficiency;
  const batterySupply = Math.min(peakEnergy, effectiveBatteryCapacity);
  const gridPeakEnergy = peakEnergy - batterySupply;
  const chargingEnergy = batterySupply / batteryEfficiency;
  const totalOffPeakEnergy = offPeakEnergy + chargingEnergy;

  const costWithoutBatteries = peakEnergy * onPeakPriceNum + offPeakEnergy * offPeakPriceNum;
  const costWithBatteries = gridPeakEnergy * onPeakPriceNum + totalOffPeakEnergy * offPeakPriceNum;
  const dailySavings = costWithoutBatteries - costWithBatteries;
  const annualSavings = dailySavings * 365;
  const timeToROI = batteryInvestment / annualSavings;
  const savingsOver20Years = annualSavings * 20;
  const savingsOver35Years = annualSavings * 35;

  // Function to get color based on percentage (for the progress bar)
  function getColorForPercentage(pct) {
    let percent = pct / 100;
    let r, g, b = 0;
    if (percent < 0.5) {
      // Red to Yellow
      r = 255;
      g = Math.round(510 * percent);
    } else {
      // Yellow to Green
      r = Math.round(510 * (1 - percent));
      g = 255;
    }
    return `rgb(${r}, ${g}, ${b})`;
  }

  return (
    <div>
      <RouterBar router={routerData} />
      <div className="calculator">
        <div className="calculator-container">
          <h1 className="calculator-title">Peak Shaving ROI Calculator</h1>
          <p className="calculator-description">
            Investing in ESC's Solid State Batteries means long-term savings. Charge your batteries during off-peak hours when energy is cheaper, then power your home or business during peak hours to cut costs. Our calculator estimates how quickly your investment pays off by comparing energy costs with and without batteries, giving you a clear path to ROI. With custom battery options, you can maximize coverage and unlock savings for the next 35+ years.
          </p>
          <div className="calculator-inputs">
            <div className="calculator-input-group">
              <label>Off-Peak Price per kWh ($)</label>
              <input
                type="number"
                value={offPeakPrice}
                onChange={(e) => setOffPeakPrice(e.target.value)}
                step="0.01"
                min="0.01"
                max="20"
              />
            </div>
            <div className="calculator-input-group">
              <label>On-Peak Price per kWh ($)</label>
              <input
                type="number"
                value={onPeakPrice}
                onChange={(e) => setOnPeakPrice(e.target.value)}
                step="0.01"
                min="0.01"
                max="30"
              />
            </div>
            <div className="calculator-input-group">
              <label>Total Daily Energy Consumption (kWh)</label>
              <input
                type="number"
                value={totalDailyEnergy}
                onChange={(e) => setTotalDailyEnergy(parseFloat(e.target.value))}
                step="1"
                min="1"
                max="200"
              />
            </div>
            <div className="calculator-input-group">
              <label>Percent of Energy Used During Peak Hours (%)</label>
              <input
                type="number"
                value={peakUsagePercent}
                onChange={(e) => setPeakUsagePercent(parseFloat(e.target.value))}
                step="1"
                min="1"
                max="100"
              />
            </div>
          </div>

          {/* Amount Spent on Batteries Slider */}
          <div className="calculator-slider-group">
            <label>Amount Spent on Batteries ($)</label>
            <input
              type="range"
              value={batteryInvestment}
              onChange={(e) => {
                const investment = parseFloat(e.target.value);
                setBatteryInvestment(investment);
                updateCoverageFromInvestment(investment);
              }}
              min="0"
              max="75000"
              step="500"
            />
            <div className="slider-value">${batteryInvestment.toLocaleString()}</div>
          </div>

          {/* Battery Coverage Slider (integrated into progress bar) */}
          <div className="coverage-indicator">
            <label>Battery Coverage of Peak Energy Consumption (%)</label>
            <input
              type="range"
              value={batteryCoveragePercentage}
              onChange={(e) => {
                const coverage = parseFloat(e.target.value);
                setBatteryCoveragePercentage(coverage);
                updateInvestmentFromCoverage(coverage);
              }}
              min="0"
              max="100"
              step="1"
              className="coverage-slider"
              style={{
                background: `linear-gradient(to right, ${getColorForPercentage(batteryCoveragePercentage)} 0%, ${getColorForPercentage(batteryCoveragePercentage)} ${batteryCoveragePercentage}%, #e0e0e0 ${batteryCoveragePercentage}%, #e0e0e0 100%)`,
              }}
            />
            <div className="slider-value">{batteryCoveragePercentage.toFixed(0)}%</div>
          </div>

          <div className="calculator-results">
            <h2>Results</h2>
            <p>
              <strong>Daily Cost Without Batteries:</strong> $
              {costWithoutBatteries.toFixed(2)}
            </p>
            <p>
              <strong>Daily Cost With Batteries:</strong> $
              {costWithBatteries.toFixed(2)}
            </p>
            <p>
              <strong>Daily Savings:</strong> ${dailySavings.toFixed(2)}
            </p>
            <p>
              <strong>Annual Savings:</strong> ${annualSavings.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
            </p>
            <p>
              <strong>Time to ROI:</strong> {timeToROI ? timeToROI.toFixed(2) : 0} years
            </p>
            <p>
              <strong>Savings Over 20 Years:</strong> $
              {savingsOver20Years.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
            </p>
            <p>
              <strong>Savings Over 35 Years:</strong> $
              {savingsOver35Years.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Calculator;
